import { Component, OnInit  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  cid: any;
  showPage:boolean=false;
  contact: any;
  contactFields: any;
  loopInput:any[]=[];
  properties:any={};
  selectedProperties:any={};
  viewSelectedProperties:any[];
  viewProperties:any[];
  title:string;
  titleUpper: string;

  constructor(private data:DataService,private snackBar: MatSnackBar,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(c=>{
      this.cid=c.id;
      if(this.cid){
        this.title="Επεξεργασία";
        this.titleUpper="ΕΠΕΞΕΡΓΑΣΙΑ";
      }
    });

    this.data.getContactById(this.cid).subscribe(c=>{
      let contactObject={}
      if(c.count<1){
        this.showPage=false;
        console.log('Not Found');
      }
      this.contact=c.results[0];
      if(this.contact.groups){
        this.loopInput=this.data.transformArray(this.contact.groups.map(c=>c.name));
        this.contact.groups.forEach(c => {
          contactObject[c.name]=c;
        });
        this.loopInput=this.loopInput.map(c=>c.map(d=>({_id:contactObject[JSON.stringify(d).slice(0,-1)]._id,name:d})));
      }
      if(!this.contact.fields.status)
        this.contact.fields.status="";
      this.contactFields=c.fields;

      this.data.getProperties("").subscribe(r=>{
        r.results.forEach(c=>{
          this.properties[c.name]=c._id
        });
        this.viewProperties=Object.keys(this.properties);
        if(this.contact.properties){
          this.contact.properties.forEach(c=>{
            this.selectedProperties[c.name]=c._id
          });
          this.viewSelectedProperties=Object.keys(this.selectedProperties);
        }
      },e=>console.log(e))

      this.showPage=true;

    },e=>{this.showPage=false;console.log(e)});
  }

  onYesClick(f:NgForm){
    Object.keys(f.form.value).forEach(c=>f.form.value[c]=this.data.sanitizeString(f.form.value[c]));
    f.form.value["groups"]=[].concat(...this.loopInput.map(c=>c.map(d=>d._id)));
    f.form.value["properties"]=this.viewSelectedProperties.map(c=>this.properties[c]);
    this.data.updateContacts(this.cid,f.form.value).subscribe(d=>{
      if(d[0].result&&d[0].result.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        f.form.reset();
      }
      else
        this.snackBar.open("Δεν έγινε κάποια αλλαγή",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  onGroupUpdate(data){
    this.loopInput=data;
  }

  onFieldsChange(data){
    this.viewSelectedProperties=data;
  }

}
