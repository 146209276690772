import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryListComponent } from './components/categories/category-list/category-list.component';
import { SigninComponent } from './components/signin/signin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { ContactListComponent } from './components/contacts/contact-list/contact-list.component';
import { ContactEditComponent } from './components/contacts/contact-edit/contact-edit.component';
import { ContactInsertComponent } from './components/contacts/contact-edit/contact-insert.component';
import { PropertiesListComponent } from './components/property/properties-list/properties-list.component';
import { FieldsListComponent } from './components/field/fields-list/fields-list.component';
import { UsersListComponent } from './components/user/users-list/users-list.component';
import { BulkInsertMainComponent } from './components/bulk-insert/bulk-insert-main/bulk-insert-main.component';
import { BulkInsertUsersComponent } from './components/bulk-insert/bulk-insert-users/bulk-insert-users.component';
import { BulkInsertGroupsComponent } from './components/bulk-insert/bulk-insert-groups/bulk-insert-groups.component';
import { BulkInsertPropertiesComponent } from './components/bulk-insert/bulk-insert-properties/bulk-insert-properties.component';
import { ChangePasswordViewComponent } from './components/change-password-view/change-password-view.component';

const appRoutes: Routes = [
    { path: 'login', component: SigninComponent, canActivate:[AuthGuardLoginService] },
    { path: 'home', component: DashboardComponent, canActivate: [AuthGuardService], children:[
        { path: 'contacts', component: ContactListComponent, canActivate: [AuthGuardService] },
        { path: 'contactEdit/:id', component: ContactEditComponent, canActivate: [AuthGuardService],pathMatch: 'full' },
        { path: 'contactInsert', component: ContactInsertComponent, canActivate: [AuthGuardService],pathMatch: 'full'},
        { path: 'categories', component: CategoryListComponent, canActivate: [AuthGuardService] },
        { path: 'properties', component: PropertiesListComponent, canActivate: [AuthGuardService] },
        { path: 'fields', component: FieldsListComponent, canActivate: [AuthGuardService] },
        { path: 'users', component: UsersListComponent, canActivate: [AuthGuardService] },
        { path: 'bulk-insert', component: BulkInsertMainComponent, canActivate: [AuthGuardService] },
        { path: 'bulk-insert-users', component: BulkInsertUsersComponent, canActivate: [AuthGuardService] },
        { path: 'bulk-insert-groups', component: BulkInsertGroupsComponent, canActivate: [AuthGuardService] },
        { path: 'bulk-insert-properties', component: BulkInsertPropertiesComponent, canActivate: [AuthGuardService] },
        { path: 'change-password-view', component: ChangePasswordViewComponent, canActivate: [AuthGuardService] }
    ] },
    { path: '', component: DashboardComponent, canActivate: [AuthGuardService] },
    { path: '**', component: DashboardComponent, canActivate: [AuthGuardService] }
];

@NgModule({imports: [
        RouterModule.forRoot(appRoutes, { enableTracing: false })
    ],
    exports: [RouterModule]
})

export class AppRoutingModule { }
