import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  selectedGroup:any;
  updatedGroup:any;
  showEdit:boolean=false;
  showDeleteConnections:boolean=false;
  showInsertConnections:boolean=false;
  selectedChildren=[];
  public eventUpdateGroups: Subject<void>=new Subject();
  public eventUpdateProperties: Subject<void>=new Subject();
  public eventRefreshGroups: Subject<void>=new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  emitEventUpdateGroups(){
    this.eventUpdateGroups.next();
  }

  emitEventUpdateProperties(){
    this.eventUpdateProperties.next();
  }

  emitEventRefreshGroups(){
    this.eventRefreshGroups.next();
  }

  getUnits(page: string) {
    const params = new HttpParams().set('perPage', '12').set('page', page);
    return this.http.get<any>('/units', { params })
      .pipe(map(resp => {
        return resp.data;
      }));
  }

  getContacts(projections,status,searchText,letter,properties,groups,pageSize,pageIndex,sort){
    return this.http.post<any>('/api/contact/search',{
      "status":status,
      "search": searchText,
      "start": letter?{"field":"lastname","value":letter}:letter,
      "properties": properties,
      "groups":groups,
      "sort": sort,
      "pageSize": pageSize,
      "page": pageIndex,
      "projections": projections
    }).pipe(map(resp => {
        if(resp&&resp.results){
          resp.results.forEach(c=>{
            if (c.fields&&c.fields.email){
              if(c.fields.email[c.fields.email.length-1]==='@'&&c.fields.email[c.fields.email.length-2]==='@')
                c.fields.email="";
            }
          })
        }
        return resp
    }));
  }

  insertManyContacts(dataArray){
    let params=dataArray.map(c=>({"criteria":c.email?{"email":c.email}:c,"update":c}));      
    return this.http.post<any>('/api/contact/upsertMany',params);
  }
  
  forgetPassword(email){
    return this.http.post<any>('/api/user/forgetPassword',{email: email});
  }
  // insertManyContacts(dataArray){
  //   let params=dataArray.map(c=>{
  //     let b = {};
  //     let d = {};
  //     Object.keys(c).forEach(l =>{
  //       if(l=="groups") d = c.groups;
  //       else b[l] = c[l];
  //     });
  //     return {
  //       "criteria":c.email?{"email":c.email}:c,
  //       "update":b,
  //       "updateGroup": d
  //     }
  //   });
    
  //   return this.http.post<any>('/api/contact/upsertMany',params);
  // }

  getContactsByGroup(groups,properties,searchText,id,pageSize,pageIndex,groupsOut){
    return this.http.post<any>('/api/contact/search',{
      "status":null,
      "search": searchText,
      "start": "",
      "properties": properties,
      "groups":groups,
      "groupId":id,
      "sort": "",
      "pageSize": pageSize,
      "page": pageIndex,
      "projections": "",
      "groupsOut":groupsOut
    }).pipe(map(resp => {
      if(resp&&resp.results){
        resp.results.forEach(c=>{
          if (c.fields&&c.fields.email){
            if(c.fields.email[c.fields.email.length-1]==='@'&&c.fields.email[c.fields.email.length-2]==='@')
              c.fields.email="";
          }
        })
      }
      return resp
    }));
  }

  getContactsByProperties(groups,properties,searchText,id,pageSize,pageIndex,propertiesOut){
    return this.http.post<any>('/api/contact/search',{
      "status":null,
      "search": searchText,
      "start": "",
      "properties": properties,
      "groups":groups,
      "propertyId":id,
      "sort": "",
      "pageSize": pageSize,
      "page": pageIndex,
      "projections": "",
      "propertiesOut":propertiesOut
    }).pipe(map(resp => {
      if(resp&&resp.results){
        resp.results.forEach(c=>{
          if (c.fields&&c.fields.email){
            if(c.fields.email[c.fields.email.length-1]==='@'&&c.fields.email[c.fields.email.length-2]==='@')
              c.fields.email="";
          }
        })
      }
      return resp
    }));
  }

  updateContacts(id,update){
    return this.http.post<any>('/api/contact/upsert',[
      {
      "criteria":{"_id":id},
      "update":update
      }
    ])
  }

  insertContacts(values){
    return this.http.post<any>('/api/contact/upsert',[
      {
        "criteria":values,
        "update":values
      }
    ])
  }

  deleteForever(params){
    return this.http.post<any>('/api/deleted/backup',params)
  }

  getContactById(id){
    return this.http.post<any>('/api/contact/search',
      {
      "id":id,
      "status":"any",
      "search": "",
      "start": "",
      "properties": "",
      "groups":[],
      "sort": "",
      "pageSize": "",
      "page": "",
      "projections": {}
      }).pipe(map(resp => {
        if(resp&&resp.results){
          resp.results.forEach(c=>{
            if (c.fields&&c.fields.email){
              if(c.fields.email[c.fields.email.length-1]==='@'&&c.fields.email[c.fields.email.length-2]==='@')
                c.fields.email="";
            }
          })
        }
        return resp
      }));
  }

  getFields(){
    return this.http.post<any>('/api/field/get',{});
  }

  getGroups(exp,level){
    return this.http.post<any>('/api/group/get',{
      "exp":exp,
      "level":level
    });
  }

  createGroup(group1,group2,group3){
    return this.http.post<any>('/api/group/create',{
      "group1":group1,
      "group2":group2,
      "group3":group3
    })
  }

  createGroups(params){
    return this.http.post<any>('/api/group/create',params)
  }

  updateGroup(query,update){
    return this.http.post<any>('/api/group/update',{
      "query":query,
      "update":update,
      "notUpsert":true
    },{observe: 'response'})
  }

  deleteGroup(name){
    console.log(name)
    return this.http.post<any>('/api/group/delete',{
      "name":name
    })
  }

  inactiveGroup(groupId){
    return this.http.post<any>('/api/contact/setInactive',{
      "groupId":groupId
    })
  }

  deleteGroupConnection(cid,exp,level){
    return this.http.post<any>('/api/contact/deleteGroupConnection',{
      "id":cid,
      "exp":[exp],
      "level":level
    })
  }

  addGroupConnection(cid,exp,level){
    return this.http.post<any>('/api/contact/contactLinkToGroup',{
      "id":cid,
      "exp":[exp],
      "level":level
    })
  }

  deletePropertyConnection(cid,pid){
    return this.http.post<any>('/api/contact/deletePropertyConnection',{
      "id":cid,
      "property":[pid]
    })
  }

  addPropertyConnection(cid,pid){
    return this.http.post<any>('/api/contact/contactLinkToProperty',{
      "id":cid,
      "property":[pid]
    })
  }

  getProperties(exp){
    return this.http.post<any>('/api/property/get',{
      "exp":exp
    });
  }

  createProperties(name){
    return this.http.post<any>('/api/property/create',{
      "query":name.map(c=>({name:c}))
    });
  }

  updateProperty(id,name){
    return this.http.post<any>('/api/property/update',{
      "query":{_id:id},
      "update":{name:name}
    });
  }

  deleteProperty(id){
    return this.http.post<any>('/api/property/delete',{
      "query":id
    });
  }

  insertFields(field){
    return this.http.post<any>('/api/field/upsert',{
      "query":field,
      "update":field
    });
  }

  updateFields(field){
    return this.http.post<any>('/api/field/upsert',{
      "query":{_id:field._id},
      "update":field
    });
  }

  deleteField(field){
    return this.http.post<any>('/api/field/delete',{
      "query":{_id:field._id}
    });
  }

  insertUsers(user){
    return this.http.post<any>('/api/user/signup',
      user
    );
  }

  updateUsers(user){
    let id=user._id;
    delete user._id
    return this.http.post<any>('/api/user/upsert',{
      users:[{
      "criteria":{_id:id},
      "update":user
      }]
    });
  }

  upsertUsers(params){
    return this.http.post<any>('/api/user/upsert',{users:params,isInsert:true});
  }

  deleteUsers(user){
    return this.http.post<any>('/api/user/upsert',[{
      "criteria":{_id:user._id},
      "update":{status:"Inactive"}
    }]);
  }

  searchUsers(searchText,status,roles,firstLogin,pageSize,pageInt,sort){
    return this.http.post<any>('/api/user/search',{
      "search":searchText,
      "sort":sort,
      "status":status,
      "firstLogin":firstLogin,
      "roles":roles,
      "pageSize":pageSize,
      "page":pageInt
    });
  }

  getUserFirstLogin(name,password){
    return this.http.post<any>('/api/user/find',{
      "query":{username:name,password:password,status:null},
      "projections":{firstLogin:true,_id: true,password:true}
    });
  }

  changeUserPassword(username,oldPassword,newPassword){
    return this.http.post<any>('/api/user/changePassword',{
      "username":username,
      "oldPassword":oldPassword,
      "newPassword":newPassword
    });
  }

  sentMail(email,subject,text){
    return this.http.post<any>('/api/user/sentMail',{
      "email":email,
      "subject":subject,
      "text":text
    });
  }

  sanitizeString(str){
    str = str && typeof str === 'string' && str.replace(/[*+?^${}()|[\]\\]/g, ' ').replace(/\s+/g, ' ').trim();
    return str;
  }

  deepCopy(obj){
    if(Array.isArray(obj))
      return obj.map(c=>this.deepCopy(c))
    else if(typeof obj==='object'){
      let ret={};
      Object.keys(obj).forEach(c=>{
        ret[c]=this.deepCopy(obj[c])
      })
      return ret
    }
    else
      return obj
  }

  transformArray(z){
    let w=[]
    let d=[]
    let k=0
    let s;

    for (let i=0;i<z.length;i++){
      if(i===z.length-1||z[i+1].indexOf(z[i])!==0){
          w.push(z[i])
          s=w.length-1
          d.push([]);
          w[s]=JSON.parse(w[s]+']')
          for(let j=0;j<w[s].length;j++)
              d[k].unshift(w[s].slice(0,w[s].length-j))
          k++
      }
    }
    return d
  }

  getHighestHierachicalArrays(a){
    let obj={};
    for (let i=1;i<4;i++){
      a.forEach(c=>{
        if(c.length===i&&(i===1||obj[c[0]]))
          obj[c[0]]=c;
      });
    }
    debugger
    return Object.values(obj)
  }

  getAllHighestHierachicalArrays(a){
    let obj={};
    let arr=[]
    let prevKey="";
     a.forEach(c=>{
       if(obj[JSON.stringify([c[0]])]){
          if(obj[JSON.stringify([c[0]])][JSON.stringify([c[0],c[1]])]){
              if (!obj[JSON.stringify([c[0]])][JSON.stringify([c[0],c[1]])][JSON.stringify([c[0],c[1],c[2]])])
                obj[JSON.stringify([c[0]])][JSON.stringify([c[0],c[1]])][JSON.stringify([c[0],c[1],c[2]])]={};
          }
          else
            obj[JSON.stringify([c[0]])][JSON.stringify([c[0],c[1]])]={};
       }
        else
          obj[JSON.stringify([c[0]])]={};
     });
    
    function recObjToArray(b){
        if (Object.keys(b).length){
            Object.keys(b).forEach(c=>{
                prevKey=c;
                recObjToArray(b[c])
            })
        }
        else{
            arr.push(prevKey)
        }
    }

    recObjToArray(obj);
    return arr.map(c=>c?JSON.parse(c):[]);
  }


  getSecure(){
    return this.http.get<any>('api/user/secure', {});
  }



  ////////////////////////////////////////

  // getUnitDetails(id) {
  //   return this.http.get<any>('/units/' + id).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }

  // bookUnit(id: string, year: string) {
  //   const params = new HttpParams().set('unitId', id).set('year', year);
  //   return this.http.get<any>('/units/book', { params })
  //     .pipe(map(resp => {
  //       return resp;
  //     }));
  // }
}
