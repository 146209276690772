import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {
  MatExpansionModule,
  MatDividerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatToolbarModule,
  MatIconModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatGridListModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatPaginatorIntl,
} from '@angular/material';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';

// Services
import { AuthService } from './services/auth.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';

//Components
import { ContactListComponent,ContactViewDialog,EmailListDialog } from './components/contacts/contact-list/contact-list.component';
import { ContactPaginatorIntl } from "./components/contacts/contact-list/ContactPaginatorIntl";
import { CategoryCreateComponent } from './components/categories/category-create/category-create.component';
import { ChipComponent } from './components/helpers/chip/chip.component';
import { ChipGroupsComponent } from './components/helpers/chip-groups/chip-groups.component';
import { ContactEditComponent } from './components/contacts/contact-edit/contact-edit.component';
import { ContactInsertComponent } from './components/contacts/contact-edit/contact-insert.component';
import { DropdownListComponent } from './components/helpers/dropdown-list/dropdown-list.component';
import { CategoryListComponent } from './components/categories/category-list/category-list.component';
import { GroupTreeItemComponent } from './components/helpers/group-tree-item/group-tree-item.component';
import { GroupViewtreeItemComponent } from './components/helpers/group-viewtree-item/group-viewtree-item.component';
import { SigninComponent } from './components/signin/signin.component';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { ActionNavbarComponent } from './components/layout/action-navbar/action-navbar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CategoryEditComponent } from './components/categories/category-edit/category-edit.component';
import { PropertiesListComponent } from './components/property/properties-list/properties-list.component';
import { PropertyEditComponent } from './components/property/property-edit/property-edit.component';
import { FieldsListComponent } from './components/field/fields-list/fields-list.component';
import { UsersListComponent } from './components/user/users-list/users-list.component';
import { BulkInsertMainComponent } from './components/bulk-insert/bulk-insert-main/bulk-insert-main.component';
import { ConfirmDeleteComponent } from './components/helpers/confirm-delete/confirm-delete.component';
import { BulkInsertUsersComponent } from './components/bulk-insert/bulk-insert-users/bulk-insert-users.component';
import { BulkInsertGroupsComponent } from './components/bulk-insert/bulk-insert-groups/bulk-insert-groups.component';
import { BulkInsertPropertiesComponent } from './components/bulk-insert/bulk-insert-properties/bulk-insert-properties.component';
import { ChangePasswordViewComponent } from './components/change-password-view/change-password-view.component';
import { ConfirmInactiveComponent } from './components/helpers/confirm-inactive/confirm-inactive.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DashboardComponent,
    NavbarComponent,
    ActionNavbarComponent,
    ContactListComponent,
    CategoryCreateComponent,
    ChipComponent,
    ContactViewDialog,
    ContactEditComponent,
    DropdownListComponent,
    ContactInsertComponent,
    CategoryListComponent,
    ChipGroupsComponent,
    GroupTreeItemComponent,
    GroupViewtreeItemComponent,
    CategoryEditComponent,
    PropertiesListComponent,
    PropertyEditComponent,
    FieldsListComponent,
    UsersListComponent,
    BulkInsertMainComponent,
    EmailListDialog,
    ConfirmDeleteComponent,
    BulkInsertUsersComponent,
    BulkInsertGroupsComponent,
    BulkInsertPropertiesComponent,
    ChangePasswordViewComponent,
    ConfirmInactiveComponent
  ],
  imports: [
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    MatListModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatPaginatorModule,
    MatRadioModule,
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    FlexLayoutModule,
    MatButtonModule, MatCardModule, MatFormFieldModule, MatInputModule, 
    MatToolbarModule, MatIconModule, MatSidenavModule, MatProgressSpinnerModule, MatGridListModule,
    MatButtonToggleModule,ScrollDispatchModule
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: MatPaginatorIntl, useValue: ContactPaginatorIntl() }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ContactViewDialog,EmailListDialog,ConfirmDeleteComponent,ConfirmInactiveComponent]
})
export class AppModule { }
