import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  user: User;
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.user=this.auth.currentUserValue;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

}
