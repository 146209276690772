import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password-view',
  templateUrl: './change-password-view.component.html',
  styleUrls: ['./change-password-view.component.scss']
})
export class ChangePasswordViewComponent implements OnInit {
  uname="";
  psw="";
  showFirstLogin=false;
  valueChangeSubscription:Subscription;
  @ViewChild('ffl') flform:NgForm;

  constructor(private auth: AuthService, private data:DataService, private snackBar:MatSnackBar) { }

  ngOnInit() {
    this.uname=this.auth.currentUserValue.username;
    setTimeout(()=>this.valueChangeSubscription=this.flform.valueChanges.subscribe(field => {
      if (this.flform&&field.newPassword !== field.repeatPassword) {
          this.flform.controls.repeatPassword.setErrors({ mismatch: true });
      } else {
          this.flform.controls.repeatPassword.setErrors(null);
      }
      if (this.flform&&field.newPassword === field.oldPassword) {
        this.flform.controls.newPassword.setErrors({ match: true });
      } else {
        this.flform.controls.newPassword.setErrors(null);
      }
    }),500);
  }

  onPasswordChange=(event, form: NgForm)=>{
    event.preventDefault();
    this.data.changeUserPassword(this.uname,form.value.oldPassword, form.value.newPassword).subscribe(r=>{
      if(r.result.firstLogin){
        this.snackBar.open("Επιτυχής Αλλαγή Κωδικού",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
      }else{
        this.snackBar.open("Παρουσιαστηκέ Σφάλμα",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
    },e=>{
      this.snackBar.open("Παρουσιαστηκέ Σφάλμα",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  ngOnDestroy(): void {
    this.valueChangeSubscription.unsubscribe();
  }
}
