import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {MatSnackBar, MatDialog} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDeleteComponent } from '../../helpers/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  groups:any=[];
  selectedItems:any={};
  showEdit:boolean=false;
  propertiesTitles:any[];

  constructor(public data:DataService, public snackBar:MatSnackBar,public dialog:MatDialog) { }

  ngOnInit() {
    // this.data.getGroups("","").subscribe(r=>{
    //   this.groups=r.results;
    // },err=>{console.log(err)})
  }

  toggleInsert(){
    this.data.showInsertConnections=!this.data.showInsertConnections;
  }

  confirmDeleteDialog(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {confirmString:this.data.selectedGroup.name[this.data.selectedGroup.name.length-1]}
    });

    dialogRef.afterClosed().subscribe(result => { 
      if (result)
        this.deleteGroup();
    });
  }

  deleteGroup(){
    this.data.deleteGroup(this.data.selectedGroup.name).subscribe(r=>{
      if(r){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.showEdit=false;
        this.data.showDeleteConnections=false;
        this.data.showInsertConnections=false;
        this.data.emitEventRefreshGroups();
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  updateGroup(){
    let upgrade=[...this.data.selectedGroup.name];
    upgrade[upgrade.length-1]=this.data.sanitizeString(this.data.updatedGroup);
    this.data.updateGroup(this.data.selectedGroup.name,upgrade).subscribe(r=>{
      if(r.status===200){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.selectedGroup.name=upgrade;
        this.data.emitEventRefreshGroups();
      }
      else{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

}
