import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit,OnDestroy {
  username = new FormControl('', [Validators.required, Validators.pattern('\b[A-Za-z0-9]+@[0-9]+\.[a-z]{2}\b')]);
  password = new FormControl('', [Validators.required]);
  error: string;
  loading = false;
  returnUrl: string;
  invalid = true;
  showFirstLogin=false;
  uname="";
  psw="";
  @ViewChild('ffl') flform:NgForm;
  valueChangeSubscription:Subscription;

  constructor(
    private auth: AuthService,
    private data:DataService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription&&!this.valueChangeSubscription.closed)
      this.valueChangeSubscription.unsubscribe();
  }

  onFirstLogin=(event, form: NgForm)=>{
    event.preventDefault();
    this.loading = true;
    this.data.getUserFirstLogin(form.value.username,form.value.password).subscribe(r=>{
      if (!r.results.length){
        this.loading = false;
        this.error = 'Ο Χρήστης Δεν Βρέθηκε';
      }
      else{
        if(r.results[0].firstLogin){
          this.onSignin(form);
        }
        else{
          this.uname=form.value.username;
          this.loading = false;
          this.showFirstLogin=true;
          setTimeout(()=>this.valueChangeSubscription=this.flform.valueChanges.subscribe(field => {
            if (this.flform&&field.newPassword !== field.repeatPassword) {
                this.flform.controls.repeatPassword.setErrors({ mismatch: true });
            } else {
                this.flform.controls.repeatPassword.setErrors(null);
            }
          }),500);
        }
      }
      
    },e=>{
        console.log(e.message);
        this.error = 'Λάθος κωδικός ή όνομα χρήστη';
        this.loading = false;
    });
  }

  onPasswordChange=(event, form: NgForm)=>{
    this.error = null;
    event.preventDefault();
    this.data.changeUserPassword(this.uname,form.value.oldPassword, form.value.newPassword).subscribe(r=>{
      if(r.result.firstLogin){
        this.valueChangeSubscription.unsubscribe();
        this.showFirstLogin=false;
        this.snackBar.open("Επιτυχής Αλλαγή Κωδικού",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
      }else{
        this.snackBar.open("Παρουσιαστηκέ Σφάλμα",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
    },e=>{
      this.snackBar.open("Παρουσιαστηκέ Σφάλμα",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  onSignin = (form: NgForm) => {
    this.auth.logIn(form.value.username, form.value.password)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['home/contacts']);
        },
        error => {
          console.log(error)
          this.error = 'Λάθος κωδικός ή όνομα χρήστη';
          this.loading = false;
        });
  }

  sendMail = (event, form: NgForm) =>{
    event.preventDefault();
    this.data.forgetPassword(form.value.email).subscribe(c => {
      this.error = null;
      this.snackBar.open("Στάλθηκε προσωρινός κωδικός πρόσβασης στο Email σας",null,
      {duration:5000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});    
    }, error =>{      
      if(error == "Not Found"){
        this.error ="Δεν βρέθηκε ο χρήστης";
        this.snackBar.open(this.error,null,
        {duration:5000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
   
      }else{
        this.error ="Κάτι πήγε στραβά. Προσπαθήστε αργότερα.";
        this.snackBar.open(this.error,null,
        {duration:5000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
    });
  }

}
