import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-fields-list',
  templateUrl: './fields-list.component.html',
  styleUrls: ['./fields-list.component.scss']
})
export class FieldsListComponent implements OnInit {

  field:any={name:"",title:"",vPattern:"",vRequired:false,protected:false};
  title:string="Εισαγωγή";
  titleUpper: string="ΕΙΣΑΓΩΓΗ";
  fields:any[];

  constructor(private data:DataService,private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getFields();
  }

  createFields(f:NgForm){
    f.form.value.name=this.data.sanitizeString(f.form.value.name);
    f.form.value.title=this.data.sanitizeString(f.form.value.title);
    this.data.insertFields(f.form.value).subscribe(d=>{
      if(d.results.upserted){
        this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.getFields();
        this.resetField();
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  getFields(){
    this.data.getFields().subscribe(r=>{
      this.fields=r;
    },e=>console.error(e))
  }

  toggleEdit(f){
    this.field=f;
    this.title='Επεξεργασία';
    this.titleUpper='ΕΠΕΞΕΡΓΑΣΙΑ';
    if (!f.vRequired)
      this.field.vRequired=false;
    if (!f.protected)
      this.field.protected=false;
  }

  resetField(){
    this.field={name:"",title:"",vPattern:"",vRequired:false,protected:false};
    this.title="Εισαγωγή";
    this.titleUpper="ΕΙΣΑΓΩΓΗ"

  }

  editFields(f:NgForm){
    f.form.value['_id']=this.field._id;
    f.form.value.name=this.data.sanitizeString(f.form.value.name);
    f.form.value.title=this.data.sanitizeString(f.form.value.title);
    this.data.updateFields(f.form.value).subscribe(d=>{
      if(d.results.nModified){
        this.snackBar.open("Επιτυχής Επεξεργασία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
        this.getFields();
        this.resetField();
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  deleteField(f){
    if(!f.vRequired){
      this.data.deleteField(f).subscribe(d=>{
        if(d.result.deletedCount){
          this.snackBar.open("Επιτυχής Διαγραφή",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
          this.resetField();
          this.getFields();
        }
     },e=>{
       this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
       console.log(e);
      });  
    }else{
      this.snackBar.open("Το πεδίο είναι απαραίτητο",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
    }
  }

}
