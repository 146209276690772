import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import {MatSnackBar} from '@angular/material';
@Component({
  selector: 'app-contact-insert',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactInsertComponent implements OnInit {
  cid: any;
  showPage:boolean=false;
  contact: any={};
  contactFields: any;
  title:string;
  titleUpper: string;
  properties:any={};
  viewSelectedProperties:any[]=[];
  viewProperties:any[];
  loopInput:any[]=[];

  constructor(private data:DataService,private snackBar: MatSnackBar,private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.data.getFields().subscribe(r=>{
      this.contactFields=r;
      this.contact["fields"]=r.map(c=>{return{[c.name]:""}});
      this.title="Εισαγωγή";
      this.titleUpper="ΕΙΣΑΓΩΓΗ";
      if(this.contact.fields.status==null){this.contact.fields.status="";}
      this.showPage=true;
    },e=>console.log(e))

    this.data.getProperties("").subscribe(r=>{
      r.results.forEach(c=>{
        this.properties[c.name]=c._id
      });
      this.viewProperties=Object.keys(this.properties);
    },e=>console.log(e))
  }

  onYesClick(f:NgForm){
    Object.keys(f.form.value).forEach(c=>f.form.value[c]=this.data.sanitizeString(f.form.value[c]))
    f.form.value["groups"]=[].concat(...this.loopInput.map(c=>c.map(d=>d._id)));
    f.form.value["properties"]=this.viewSelectedProperties.map(c=>this.properties[c]);
    f.form.value["status"]=f.form.value["status"]||null;
    this.data.insertContacts(f.form.value).subscribe(d=>{
      if(d[0].result&&d[0].result.upserted){
        this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        f.form.reset();
      }
      else{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  onGroupUpdate(data){
    this.loopInput=data
  }

  onFieldsChange(data){
    this.viewSelectedProperties=data;
  }

}
