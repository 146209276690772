import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import {MatSnackBar, MatDialog} from '@angular/material';
import { ConfirmDeleteComponent } from '../../helpers/confirm-delete/confirm-delete.component';
import { ExcelService } from 'src/app/services/excel.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  user:any={username:"",status:"",firstLogin:false};
  title:string="Εισαγωγή";
  titleUpper: string="ΕΙΣΑΓΩΓΗ";
  users:any[];
  roles:string[]=["UserLight","User","Moderator","Admin"];
  selectedRoles:any[]=["UserLight"];
  searchRoles:any[]=[];
  searchStatus:string="all";
  searchFirstLogin:any="all";
  searchText:string="";
  pageSize = 10;
  pageIndex=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  length = 0;
  delay: any = null;
  order:any={username:true};
  orderField:string="username";
  isInRole: boolean;

  constructor(private data:DataService, private excelService: ExcelService,private snackBar: MatSnackBar, public dialog:MatDialog, private auth:AuthService) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }

  ngOnInit() {
    this.getUsers();
  }

  createUsers(f:NgForm){
      f.form.value.username=this.data.sanitizeString(f.form.value.username);
      f.form.value['role']=this.selectedRoles;
      if (!f.form.value['status']) 
        f.form.value['status']=null;
      this.data.insertUsers([f.form.value]).subscribe(d=>{
        if(d.inserted){
          this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
          this.getUsers();
        }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
      });

  }

  getUsers(){
    if(this.delay){
      clearTimeout(this.delay);
    }
    this.delay=setTimeout(()=>{
      this.delay=null;
      this.data.searchUsers(this.searchText,this.searchStatus,this.searchRoles,this.searchFirstLogin,this.pageSize,this.pageIndex+1,this.orderField?{[this.orderField]:(this.order[this.orderField]?1:-1)}:"").subscribe(r=>{
        this.pageIndex=0;
        this.users=r.results;
        this.length=r.count;
      },e=>console.error(e))
    },1000);
  }

  orderBy(cf){
    this.order[cf]=this.order[cf]!==undefined?!this.order[cf]:true;
    this.orderField=cf;
    this.getUsers();
  }

  toggleEdit(u){
    this.user=u.fields;
    this.user['_id']=u._id;
    this.selectedRoles=u.role;
    this.title='Επεξεργασία';
    this.titleUpper="ΕΠΕΞΕΡΓΑΣΙΑ";
    if (!u.fields.firstLogin)
      this.user.firstLogin=false;
    if (!u.fields.status)
      this.user.status="";
  }

  pageChange(ps,pi){
    this.pageSize=ps;
    this.pageIndex=pi;
    this.getUsers();
  }

  exportDataAsXLSX():void {
    let exportData=[];
    this.data.searchUsers(this.searchText,this.searchStatus,this.searchRoles,this.searchFirstLogin,this.pageSize,this.pageIndex+1,this.orderField?{[this.orderField]:(this.order[this.orderField]?1:-1)}:"").subscribe(r=>{
      let usersExport=r.results;
      exportData=usersExport.map(c=>({"Όνομα Χρήστη":c.fields.username||'',"Προσωρινός Κωδικός":c.fields.temporaryPassword||'',"Πρώτη Είσοδος":c.fields.firstLogin?'ΝAI':'OXI',"Ημ.Δημιουργίας":c.fields.createdAt}));
      this.excelService.exportAsExcelFile(exportData,'data');
    },e=>console.error(e))
 }

  resetUsers(){
    this.user={username:"",status:"",firstLogin:false};
    this.selectedRoles=["UserLight"];
    this.title="Εισαγωγή";
    this.titleUpper="ΕΙΣΑΓΩΓΗ";
  }

  editUsers(f:NgForm){
    if(confirm("Πατήστε ΟΚ για επιβεβαίωση.")){
      f.form.value.username=this.data.sanitizeString(f.form.value.username);
      f.form.value['_id']=this.user._id;
      f.form.value['role']=this.selectedRoles;
      f.form.value['temporaryPassword']=this.user.temporaryPassword;
      this.data.updateUsers(f.form.value).subscribe(d=>{
        if(d[0].result && d[0].result.nModified){
          this.snackBar.open("Επιτυχής Επεξεργασία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
          this.getUsers();
          this.resetUsers();
        }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.log(e);
      });
    }
  }

  deleteUsers(u){
    this.data.deleteUsers(u).subscribe(d=>{
      if(d[0].result && d[0].result.nModified){
        this.snackBar.open("Επιτυχής Διαγραφή",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
        this.resetUsers();
        this.getUsers();
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  confirmDeleteDialog(c){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {confirmString:c.fields.username||"mail@mail.com"}
    });

    dialogRef.afterClosed().subscribe(result => { 
      if (result)
        this.deleteForeverUser(c);
    });
  }

  deleteForeverUser(c){
    this.data.deleteForever({type:"User",id:c._id}).subscribe(d=>{
      if(d._id){
        this.snackBar.open("Διαγραφή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});  
        if (this.users.length===1)
          this.pageIndex>0?this.pageIndex--:null;
        this.getUsers();
      }
      else{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  sentEmail(c){
    if(confirm("Πατήστε ΟΚ για επιβεβαίωση.")){
      if(c.fields.email&&c.fields.temporaryPassword&&c.fields.email.match(/[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+/)){
        this.data.sentMail(c.fields.email,"Προσωρινός Κωδικός","Ο προσωρινός κωδικός για εισαγωγή στο σύστημα επαφών είναι:"+c.fields.temporaryPassword).subscribe(d=>{
          if (d.accepted&&d.accepted.length)
            this.snackBar.open("Μήνυμα Εστάλη",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});  
          else
            this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
        },e=>console.log(e))
      }
      else{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
    }
  }

}
