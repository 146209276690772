import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/user';
import { AuthGuardDeactivateService } from 'src/app/services/auth-guard-deactivate.service';

@Component({
  selector: 'app-action-navbar',
  templateUrl: './action-navbar.component.html',
  styleUrls: ['./action-navbar.component.scss']
})
export class ActionNavbarComponent implements OnInit {
  
  user: User;
  isInRole: boolean;

  constructor(private data: DataService,private auth: AuthService, private router: Router) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }

  ngOnInit() {
    
  }

  secure() {
    this.data.getSecure().subscribe(data=>console.log(data));  
  }

}
