import { Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import {Router, ActivatedRoute} from "@angular/router";
import { ExcelService } from 'src/app/services/excel.service';
import { ConfirmDeleteComponent } from '../../helpers/confirm-delete/confirm-delete.component';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  JSON:JSON;
  titles:string[]=["Ομάδες Επαφών", "Κατηγορίες", "Ιδιότητες"];
  fields:any=[];
  loop:any=[];
  groups:any;
  groupsList={};
  fieldsTitles:any;
  contacts: any=null;
  contactFields: any=null;
  delay: any = null;
  oldValue:any=null;
  alphabet:string[]=["","Α","Β","Γ","Δ","Ε","Ζ","Η","Θ","Ι","Κ","Λ","Μ","Ν","Ξ","Ο","Π","Ρ","Σ","Τ","Υ","Φ","Χ","Ψ","Ω"];
  length = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  properties:any={};
  propertiesTitles:any[];
  oldFilters:any={};
  fieldsMap:any={};
  isInRole:boolean;

  filters:any={
    pageIndex:0,
    pageSize : 10,
    selectedItems:{},
    order:{firstname:true},
    orderField:"firstname",
    selectedProperties:[],
    status:"",
    searchText:"",
    selectedFields:[],
    selectedLetter:""
  }

  constructor(private router: Router,private route: ActivatedRoute, private auth:AuthService, private excelService: ExcelService,private data:DataService,public dialog:MatDialog, private snackBar:MatSnackBar) {
    this.route.queryParams.subscribe(r=>{
      if(Object.keys(r).length){
        this.filters=JSON.parse(r.filters);
        this.getContacts();  
      }
      else{
        this.filters={
          pageIndex:0,
          pageSize : 10,
          selectedItems:{},
          order:{firstname:true},
          orderField:"firstname",
          selectedProperties:[],
          status:"",
          searchText:"",
          selectedFields:[],
          selectedLetter:""
        };
        this.getContacts(); 
      }
    },e=>console.error(e));
    this.isInRole = this.auth.isInRole(['Admin']);
   }

  ngOnInit() {
    this.JSON=JSON;
    this.data.getFields().subscribe(c=>{
      this.fields=c;
      this.fieldsTitles=c.map(c=>c.title);
      c.forEach(c=>this.fieldsMap[c.name]=c.title)
    },e=>{console.log(e)});

    this.data.getProperties("").subscribe(r=>{
      r.results.forEach(c=>{
        this.properties[c.name]=c._id;
      });
      this.propertiesTitles=Object.keys(this.properties);
    },e=>console.log(e))
  }

  getContacts(){
    let projections={};
    if(this.delay){
      clearTimeout(this.delay);
    }
    // if (JSON.stringify(this.filters)!==JSON.stringify(this.oldFilters)){
      this.delay=setTimeout(()=>{
        // this.delay=null;
        // setTimeout(()=>{
        //   this.oldFilters={...this.filters};
        // },2000)
        this.fields.forEach(f=>{
          if(this.filters.selectedFields.indexOf(f.title)>=0)
            projections[f.name]=true;
        });
        this.data.getContacts(projections,this.filters.status,this.filters.searchText,this.filters.selectedLetter,this.filters.selectedProperties.map(c=>this.properties[c]),Object.keys(this.filters.selectedItems).filter(c=>this.filters.selectedItems[c]).map(c=>JSON.parse(c)._id),this.filters.pageSize,this.filters.pageIndex+1,this.filters.orderField?{[this.filters.orderField]:(this.filters.order[this.filters.orderField]?1:-1)}:"").subscribe(c=>{
          this.contacts=c.results;
          this.contactFields=c.fields;
          this.length=c.count;
        },e=>{
          console.error(e);
          this.contacts=[];
        });
      },200);
    // }
  }

  search() {
    setTimeout(()=>{
        Object.keys(this.filters.selectedItems).forEach(c=>{if (!this.filters.selectedItems[c])  delete this.filters.selectedItems[c];});
        this.router.navigate(['.'], {
        queryParams: {filters:JSON.stringify(this.filters)}, 
        queryParamsHandling: 'merge',
        relativeTo:this.route
      })
    })
  }

  resetFilters(){
    this.filters={
      pageIndex:0,
      pageSize : 10,
      selectedItems:{},
      order:{firstname:true},
      orderField:"firstname",
      selectedProperties:[],
      status:"",
      searchText:"",
      selectedFields:[],
      selectedLetter:""
    };
  }

  orderBy(cf){
    this.filters.order[cf]=this.filters.order[cf]!==undefined?!this.filters.order[cf]:true;
    this.filters.orderField=cf;
    this.search();
  }

  pageChange(ps,pi){
    this.filters.pageSize=ps;
    this.filters.pageIndex=pi;
    this.search();
  }

  onFieldsChange(data){
    this.filters.selectedFields=data;
    this.search();
  }

  onPropertiesChange(data){
    this.filters.selectedProperties=data;
    this.search();
  }

  onCategoriesChange(data){
    this.loop=data.map(c=>c.map(d=>d._id));
    this.search();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  deactivateContact(c){
    this.data.updateContacts(c._id,{status:'Inactive'}).subscribe(d=>{
      if(d[0].result.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.search();
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  confirmDeleteDialog(c){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {confirmString:c.fields.email||"mail@mail.com"}
    });

    dialogRef.afterClosed().subscribe(result => { 
      if (result)
        this.deleteForeverContact(c);
    });
  }

  deleteForeverContact(c){
    this.data.deleteForever({type:"Contact",id:c._id}).subscribe(d=>{
      if(d._id){
        this.snackBar.open("Διαγραφή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});  
        if (this.contacts.length===1){
          if(this.filters.pageIndex>0){
            this.filters.pageIndex--;
            this.search();
          }
          else
            this.getContacts();
        }
        else{
          this.getContacts();
        }
      }
      else{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.log(e);
    });
  }

  exportDataAsXLSX():void {
    let projections={};
    let exportData=[];
    this.fields.forEach(f=>{
      if(this.filters.selectedFields.indexOf(f.title)>=0)
        projections[f.name]=true;
    });
    this.data.getContacts(projections,this.filters.status,this.filters.searchText,this.filters.selectedLetter,this.filters.selectedProperties.map(c=>this.properties[c]),Object.keys(this.filters.selectedItems).filter(c=>this.filters.selectedItems[c]).map(c=>JSON.parse(c)._id),"","",this.filters.orderField?{[this.filters.orderField]:(this.filters.order?1:-1)}:"").subscribe(c=>{
      exportData=c.results.map(c=>{
        let obj={};
        if(!this.filters.selectedFields.length||this.filters.selectedFields.includes("status"))
          !c.fields.status?c.fields.status='Ενεργός':'Ανενεργός';
        Object.keys(c.fields).forEach(d=>delete Object.assign(c.fields, {[this.fieldsMap[d]]: c.fields[d] })[d]);
        this.fields.forEach(d=>obj[d.title]=c.fields[d.title]||"");
        obj["Κατηγορίες"]=this.data.getAllHighestHierachicalArrays(c.groups.map(d=>JSON.parse(d.name+']'))).map(c=>!c||!c.length?"":c.join().replace(/,/g,'|'));
        obj["Κατηγορίες"]=!obj["Κατηγορίες"]||!obj["Κατηγορίες"].length?"":obj["Κατηγορίες"].join(';');
        obj["Ιδιότητες"]=c.properties.map(d=>d.name).join(';');
        return obj
      });
      this.excelService.exportAsExcelFile(exportData,'data');
    },e=>{
      console.error(e);
    });
 }

  openViewDialog(c){
    const dialogRef = this.dialog.open(ContactViewDialog, {
      panelClass: 'my-full-screen-dialog',
      data: {contact: c, contactFields: this.contactFields}
    });

    dialogRef.afterClosed().subscribe(result => { 
      this.search();
    });
  }

  openEmailDialog(){
    let emailList:string="";
    let dialogRef:any;
    let projections={};
    if (this.filters.selectedFields.includes("Email"))
      projections['email']=true;
    if (this.filters.selectedFields.includes("Email2"))
      projections['email2']=true;
    if (this.filters.selectedFields&&this.filters.selectedFields.length===0){
      projections['email']=true;
      projections['email2']=true;
    } 

    this.data.getContacts(projections,this.filters.status,this.filters.searchText,this.filters.selectedLetter,this.filters.selectedProperties.map(c=>this.properties[c]),Object.keys(this.filters.selectedItems).filter(c=>this.filters.selectedItems[c]).map(c=>JSON.parse(c)._id),"","",this.filters.orderField?{[this.filters.orderField]:(this.filters.order?1:-1)}:"").subscribe(c=>{
      c.results.forEach(c=>{
        if(c.fields.email&&c.fields.email.substring(0,3)!=="FFF")
          c.fields.email?emailList+=c.fields.email+';':null;
        if(c.fields.email2&&c.fields.email2.substring(0,3)!=="FFF")
          c.fields.email2?emailList+=c.fields.email2+';':null;
      })
      dialogRef = this.dialog.open(EmailListDialog, {
        panelClass: 'my-full-screen-dialog',
        data: emailList
      });

    },e=>{
      console.error(e);
    });
  }
}

@Component({
  selector: 'contact-view-dialog',
  templateUrl: 'contact-view-dialog.html'
})
export class ContactViewDialog {

  groupTitles:any;
  propertiesTitles:any;

  constructor(
    private data:DataService,
    public dialogRef: MatDialogRef<ContactViewDialog>,
    @Inject(MAT_DIALOG_DATA) public props: {contact:any;contactFields:any}) {
      this.groupTitles=this.data.transformArray(this.props.contact.groups.map(c=>c.name));
      this.propertiesTitles=this.props.contact.properties.map(c=>c.name);
    }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'email-list-dialog',
  templateUrl: 'email-list-dialog.html',
})
export class EmailListDialog {

  constructor(
    private data:DataService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContactViewDialog>,
    @Inject(MAT_DIALOG_DATA) public props: any) {
    }

  copyToClip(){
    let cText:any=document.getElementById("eList");
    cText.select();
    document.execCommand("copy");
    this.snackBar.open("Η Λίστα Αντιγράφηκε",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-info']});
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
  


