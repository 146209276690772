import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {MatSnackBar} from '@angular/material';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-group-viewtree-item',
  templateUrl: './group-viewtree-item.component.html',
  styleUrls: ['./group-viewtree-item.component.scss']
})
export class GroupViewtreeItemComponent implements OnInit,OnDestroy {

  @Input() group:any;
  @Input() level:number;
  @Input() isLast:boolean;
  @Input() isFirst:boolean;
  child:any;
  JSON:JSON;
  groupList={};
  @Input() model:any;
  showCreate:boolean=false;
  newGroup:string="";
  search:string="";
  delay:any;
  originalChild:any;
  subscriptionRefreshGroups:Subscription;
  loaded:boolean=true;
  maxPage:number=0;
  page:number=0;
  pageSize:number=100;
  isInRole: boolean;

  constructor(private data:DataService, private snackBar:MatSnackBar, private auth:AuthService) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }

  ngOnInit() {
    this.JSON=JSON;
    if(this.level===1){
      this.getChild(this.group);
    }
    if(!this.group){
      this.subscriptionRefreshGroups=this.data.eventRefreshGroups.subscribe(()=>{
        this.child=this.originalChild=null;
        this.getChild("");
      });
    }
  }

  ngOnDestroy(){
    if(!this.group)
      this.subscriptionRefreshGroups.unsubscribe();
  }

  pageBack(pg){
    this.page=pg;
    if(this.child){
      this.page>0?this.page--:0;
      this.getGroups(this.page);
    }
  }

  pageForward(pg){
    this.page=pg;
    if(this.child){
      (this.maxPage>=this.page+1)?this.page++:this.page;
      this.getGroups(this.page);
    }
  }

  getGroups(pg){
    if(!pg)
      this.page=0;
    if(this.delay){
      clearTimeout(this.delay);
    }
    this.delay=setTimeout(()=>{
      this.loaded=false;
      if(this.search)
        this.child=this.originalChild.filter(c=>c.name[c.name.length-1].match(new RegExp(this.search,'i')));
      else
        this.child=[...this.originalChild];
      this.maxPage=Math.ceil(this.child.length/this.pageSize);
      this.child=this.child.slice(pg*this.pageSize,(pg+1)*this.pageSize);
      this.loaded=true;
    },100);
  }

  getChild(g){
    this.loaded=false;
    this.page=0;
    (this.originalChild&&this.originalChild.length)||this.data.getGroups(this.group?this.group.name:this.group,this.level).subscribe(r=>{
      this.child=r.results.map(c=>({_id:c._id,name:JSON.parse(c.name+']')}));
      this.originalChild=[...this.child];
      this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loaded=true;
      this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
    },e=>{console.error(e);this.loaded=true;});
    if(g&&this.originalChild&&this.originalChild.length){
      this.child=this.child.filter(c=>!c.name.includes(g.name[g.name.length-1]));
      this.originalChild=[...this.child];
      this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loaded=true; 
      this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
    }
    this.search="";
  }

  editGroup(g){
    this.data.selectedGroup=g;
    this.data.updatedGroup=g.name[g.name.length-1];
    this.data.showEdit=!this.data.showEdit;
    if(this.data.showEdit)
      setTimeout(()=>window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight),50);
  }

  editGroupConnections(g){
    this.data.selectedGroup=g;
    this.data.updatedGroup=g.name[g.name.length-1];
    this.data.showDeleteConnections=!this.data.showDeleteConnections;
    this.data.showInsertConnections=false;
    if(this.data.showDeleteConnections)
      setTimeout(()=>window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight),50);
  }


  changeEvent(ev:any){
    let updVal=this.model[JSON.stringify(ev)]
    if(!updVal) {
      Object.keys(this.model).filter(c=>(JSON.parse(c).name).indexOf(ev.name[ev.name.length-1])>=0).forEach(c=>{this.model[c]=false});
      this.child=null;
    }
    else
      Object.keys(this.model).filter(c=>ev.name.includes(JSON.parse(c).name[JSON.parse(c).name.length-1])).forEach(c=>{this.model[c]=true});
  }

  saveGroup(newGroup){
    newGroup=this.data.sanitizeString(newGroup);
    let group1:string="";let group2:string="";let group3:string="";
    
    if(this.level===3){
      group1=this.group.name[0];group2=this.group.name[1];group3=newGroup;
    }
    else if(this.level===2){
      group1=this.group.name[0];group2=newGroup;
    }
    else{
      group1=newGroup;
    }

    (this.group&&this.group.length)||this.data.createGroup(group1,group2,group3).subscribe(r=>{
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.showCreate=false;
        if(!this.group)
          this.child=this.originalChild=null;
        this.getChild(this.group)
      },e=>{
        this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
        console.error(e);
      });
    this.newGroup="";
  }

}
