import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { filter,take,switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (request.url.includes("token") ||request.url.includes("login")) {
        // We do another check to see if refresh token failed
        // In this case we want to logout user and to redirect it to login page

        if (request.url.includes("token")) {
            this.authService.logout();
            location.reload(true);
        }

        return throwError(err.error.message || err.statusText);
    }

      if (err.status === 401) {
        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null)
            ,take(1)
            ,switchMap(() => {return next.handle(this.addAuthenticationToken(request))})
            );
        }
        else{
          this.refreshTokenInProgress = true;
          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);
          return this.authService.sendRefreshToken().pipe(
            switchMap(token=>{
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);
              return next.handle(this.addAuthenticationToken(request));
            })
            ,catchError(err=>{
              this.refreshTokenInProgress = false;

              this.authService.logout();
              location.reload(true);

              return throwError(err.error.message || err.statusText);
            })
          )
        }
      }
      else{

        return throwError(err.error.message || err.statusText);
      }
  
    }));
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const response = this.authService.sendRefreshToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!response) {
        return request;
    }

    const currentUser = this.authService.currentUserValue;
    // We clone the request, because the original request is immutable
    return request.clone({
        setHeaders: {
            Authorization: 'Bearer '+currentUser.accessToken
        }
    });
  }

}
