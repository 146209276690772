import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-bulk-insert-users',
  templateUrl: './bulk-insert-users.component.html',
  styleUrls: ['./bulk-insert-users.component.scss']
})
export class BulkInsertUsersComponent implements OnInit {

  fields:any={};
  fieldTitles:any={};
  exportData:any={};
  disableDownload:boolean=true;
  dataArray:any=[];
  dataArrayB:any=[];
  showArray:any=[];
  object:Object;
  length:number=0;
  pageSize:number=10;
  page:number=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loading:boolean=false;
  error:string;
  loadingPaste:boolean=false;
  isInRole: any;

  constructor(private data:DataService,private excelService: ExcelService,private snackBar: MatSnackBar,private auth:AuthService) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }

  ngOnInit() {
    this.object=Object;
      this.fields["Όνομα Χρήστη"]='username';
      this.fieldTitles['username']="Όνομα Χρήστη";
      this.fields["Ρόλοι"]='role';
      this.fieldTitles['role']="Ρόλοι";
      this.fields["Email"]='email';
      this.fieldTitles['email']="Email";

      this.disableDownload=false;
  }

  onPaste(event: ClipboardEvent){
    this.loadingPaste=true;
    this.error="";
    let tempArray=[];
    event.clipboardData.getData('text').split('\n').forEach((c,i)=>{
      if (c&&c.trim()) 
        tempArray.push(c.split('\t').map(d=>d.trim()))}
    );
    this.dataArray=tempArray.slice(1).map(c=>{
      let tmp={};
      tempArray[0].forEach((d,i)=>{
          if (c[i]){
            if (this.fields[d]==='role')
              tmp[this.fields[d]]=c[i].split(',').map(c=>this.data.sanitizeString(c));
            else
              tmp[this.fields[d]]=c[i];
          }
      });
      return tmp;
    })
    this.dataArray=this.dataArray.filter(c=>!c.undefined);
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
    this.length=this.dataArray.length;
    this.loadingPaste=false;
  }

  exportAsXLSX():void {
    Object.keys(this.fields).forEach(c=>{this.exportData[c]=''});
    this.excelService.exportAsExcelFile([this.exportData], 'template_user');
 }

  exportDataAsXLSX():void {
    let exportData=this.data.deepCopy(this.dataArray);
    exportData=exportData.map(c=>{
      Object.keys(this.fieldTitles).forEach(e=>{
        if (!c[e])
          c[e]="";
      });

      Object.keys(c).map(d=>delete Object.assign(c, {[this.fieldTitles[d]]: c[d] })[d]);
      return c;
      })
    this.excelService.exportAsExcelFile(exportData,'data');
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 pageChange(pSize,pIndex){
  this.pageSize=pSize;
  this.page=pIndex;
  this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 requestSequence(){
  let requestTable=[];
  this.dataArrayB=[...this.dataArray];
  while(this.dataArray.length){ 
    requestTable.push(this.dataArray.slice(0,50));
    this.dataArray=this.dataArray.slice(50);
  }
  return Promise.all(requestTable.map(c=>{
    return new Promise((resolve,reject)=>{
      this.data.upsertUsers(c.map(d=>({
        criteria:d,
        update:d
      }))).subscribe(r=>{
        r.forEach(d=>{
          let idx;
          if(d.result && (idx=this.dataArrayB.map(e=>{return e.username||''}).indexOf(d.filter.username?d.filter.username:''))>=0)
            this.dataArrayB.splice(idx,1);
        });
        resolve(r);
      },e=>{console.error(e); resolve(e)})
    })
  }))
 }

 insertData(){
  if (this.loading) return
  this.error="";
  this.loading=true;
  this.requestSequence().then(r2=>{
    this.dataArray=[...this.dataArrayB];
    if(this.dataArray.length){
      this.requestSequence().then(r3=>{
        this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
        this.loading=false;
        this.length=this.dataArray.length;
        if (this.dataArray.length)
          this.error="Σφάλμα: Κάποιες εγγραφές δεν εισήχθησαν";
        else
          this.snackBar.open("Εισαγωγή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});    
      }).catch(e=>console.error(e));
    }
    else{
      this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loading=false;
      this.length=this.dataArray.length;
      if (this.dataArray.length)
        this.error="Σφάλμα: Κάποιες εγγραφές δεν εισήχθησαν";
      else
        this.snackBar.open("Εισαγωγή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});  
    }
  })
  .catch(e=>console.error(e));
 }

}
