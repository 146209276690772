import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material';
import { ExcelService } from 'src/app/services/excel.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-bulk-insert-main',
  templateUrl: './bulk-insert-main.component.html',
  styleUrls: ['./bulk-insert-main.component.scss']
})
export class BulkInsertMainComponent implements OnInit {

  fields:any={};
  fieldsPatterns:any={};
  fieldTitles:any={};
  fieldsRequired:any={};
  exportData:any={};
  disableDownload:boolean=true;
  dataArray:any=[];
  showArray:any=[];
  object:Object;
  length:number=0;
  pageSize:number=10;
  page:number=0;
  Flength:number=0;
  FpageSize:number=10;
  Fpage:number=0;
  FshowArray:any=[];
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loading:boolean=false;
  error:string;
  loadingPaste:boolean=false;
  dataArrayB:any=[];
  falseArray:any=[];
  isInRole: boolean;

  constructor(private data:DataService,private excelService: ExcelService,private snackBar: MatSnackBar,private auth:AuthService) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }

  ngOnInit() {
    this.object=Object;
    this.data.getFields().subscribe(r=>{
      r.forEach(c=>{
        if(c.name!=="status"&&c.name!=="createdAt"){
          this.fields[c.title]=c.name;
          this.fieldsPatterns[c.name]=c.vPattern||'';
          this.fieldsRequired[c.name]=c.vRequired||'';
          this.fieldTitles[c.name]=c.title;
        }
      });
      this.fields["Κατηγορίες"]='groups';
      this.fieldTitles['groups']="Κατηγορίες";
      this.fields["Ιδιότητες"]='properties';
      this.fieldTitles['properties']="Ιδιότητες";

      this.disableDownload=false;
    },e=>console.error(e))
  }

  onPaste(event: ClipboardEvent){
    this.loadingPaste=true;
    this.error="";
    let tempArray=[];
    let evalArray=[];
    this.falseArray=[];
    event.clipboardData.getData('text').split('\n').forEach((c,i)=>{
      if (c&&c.trim()) 
        tempArray.push(c.split('\t').map(d=>d.trim()))}
    );
    this.dataArray=tempArray.slice(1).map(c=>{
      let tmp={};
      tempArray[0].forEach((d,i)=>{
          if (c[i]){
            if (this.fields[d]==='groups'){
              c[i]=c[i].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
              tmp[this.fields[d]]=c[i].split(';');
              tmp[this.fields[d]].forEach((c,i)=>{
                tmp[this.fields[d]][i]=c.split('|').map(c=>this.data.sanitizeString(c));
                if (tmp[this.fields[d]][i].length>3)
                  tmp[this.fields[d]][i]=tmp[this.fields[d]][i].slice(0,3);
              });
              tmp[this.fields[d]]=tmp[this.fields[d]].map(c=>c.filter(e=>e));
            }
            else if (this.fields[d]==='properties'){
              c[i]=c[i].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
              tmp[this.fields[d]]=c[i].split(';').map(c=>this.data.sanitizeString(c));
              tmp[this.fields[d]]=tmp[this.fields[d]].filter(c=>c);
            }
            else
              tmp[this.fields[d]]=this.data.sanitizeString(c[i]);
          }
      });
      return tmp;
    })
    this.dataArray=this.dataArray.filter(c=>!c.undefined);
    evalArray=this.dataArray.map((c,i)=>{
      if (Object.keys(this.fieldsRequired).filter(d=>this.fieldsRequired[d]&&!c[d]).length>0)
        return true
      return (Object.keys(c).some(d=>{return !(new RegExp(this.fieldsPatterns[d])).test(c[d])}));
    });
    let j=0;
    evalArray.forEach((c,i)=>{
      if (c){
        this.falseArray.push(this.dataArray[i-j]);
        this.dataArray.splice(i-j,1);
        j++;
      }
    });
    if((this.Flength=this.falseArray.length))
      this.FshowArray=this.falseArray.slice(this.Fpage*this.FpageSize,(this.Fpage+1)*this.FpageSize);
    
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
    this.length=this.dataArray.length;
    this.loadingPaste=false;
  }

  exportAsXLSX():void {
    Object.keys(this.fields).forEach(c=>{this.exportData[c]=''});
    this.excelService.exportAsExcelFile([this.exportData], 'template');
 }

  exportDataAsXLSX(dA,sA):void {
    let exportData=this.data.deepCopy(dA);
    exportData=exportData.map(c=>{
      if (c.groups){
        c.groups=c.groups.map(c=>c.join('|'));
        c.groups=c.groups.join(';')
      }

      if(c.properties){
        c.properties=c.properties.join(';');
      }
      

      Object.keys(this.fieldTitles).forEach(e=>{
        if (!c[e])
          c[e]="";
      });

      Object.keys(c).map(d=>delete Object.assign(c, {[this.fieldTitles[d]]: c[d] })[d]);
      return c;
    });
    this.excelService.exportAsExcelFile(exportData,'data');
    sA=dA.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 pageChange(pSize,pIndex){
  this.pageSize=pSize;
  this.page=pIndex;
  this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 FpageChange(pSize,pIndex){
  this.FpageSize=pSize;
  this.Fpage=pIndex;
  this.FshowArray=this.falseArray.slice(this.Fpage*this.FpageSize,(this.Fpage+1)*this.FpageSize);
 }

 requestSequence(){
  let requestTable=[];
  this.dataArrayB=[...this.dataArray];
  let emailArray=[...this.dataArrayB].map(c=>c.email);
  while(this.dataArray.length){
    requestTable.push(this.dataArray.slice(0,50));
    this.dataArray=this.dataArray.slice(50);
  }
  return Promise.all(requestTable.map((c,i)=>{
    return new Promise((resolve,reject)=>{
      this.data.insertManyContacts(c).subscribe(r=>{
        r.forEach((d,i)=>{
          let idx;
          if(d.filter && d.result){
            idx=emailArray.indexOf(c[i].email);
            idx>-1?this.dataArrayB.splice(idx,1):null;
            idx>-1?emailArray.splice(idx,1):null;
          }

          // if(d.error||!d.result)
          //   this.dataArray.push(c[i]);
        });
        resolve(r);
      },e=>{console.error(e); resolve(e)})
    })
  }))
 }

 insertData(){
  if (this.loading) return
  this.error="";
  this.loading=true;
  this.requestSequence().then(r2=>{
    this.dataArray=[...this.dataArrayB];
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
    this.loading=false;
    this.length=this.dataArray.length;
    if (this.dataArray.length)
      this.error="Σφάλμα: Κάποιες εγγραφές δεν εισήχθησαν";
    else
      this.snackBar.open("Εισαγωγή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});    
    }).catch(e=>console.error(e));
 }

}
