import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-inactive',
  templateUrl: './confirm-inactive.component.html',
  styleUrls: ['./confirm-inactive.component.scss']
})
export class ConfirmInactiveComponent {  

  constructor(
    public dialogRef: MatDialogRef<ConfirmInactiveComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {
    }

    onNoClick(): void{
      this.dialogRef.close();
    }

}
