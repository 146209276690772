import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logIn(username: string, password: string) {
    let cur: any;
    return this.http.post<any>('/api/user/login', { username: username, password: password }).pipe(map(resp => {
      if (resp && resp.accessToken) {
        cur = resp;
        cur.accessToken = resp.accessToken;
        cur.refreshToken = resp.refreshToken;
        localStorage.setItem('currentUser', JSON.stringify(cur));
        this.currentUserSubject.next(cur);
      }
      return cur;
    }));
  }

  sendRefreshToken(){
    let cur: any;
    return this.http.post<any>('/api/user/token',{refreshToken:this.currentUserValue.refreshToken,userId:this.currentUserValue.id})
    .pipe(map(resp=>{
      if (resp && resp.accessToken) {
        cur=resp;
        cur.accessToken = resp.accessToken;
        cur.refreshToken = resp.refreshToken;
        localStorage.setItem('currentUser', JSON.stringify(cur));
        this.currentUserSubject.next(cur);
      }
      return cur;
    }))
  }

  isInRole(roles=[]){

    let curRoles=this.currentUserValue.role||[];

    if (typeof roles === 'string') {
      roles = [roles];
    }

    return curRoles.some(r=> roles.includes(r));

  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
