import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-group-tree-item',
  templateUrl: './group-tree-item.component.html',
  styleUrls: ['./group-tree-item.component.scss']
})
export class GroupTreeItemComponent implements OnInit {

  @Input() group:any;
  @Input() level:number;
  @Input() isSelected:boolean=false;
  @Input() last:boolean;
  @Input() index:number;
  @Input() parent:string;
  child:any;
  originalChild:any;
  JSON:JSON;
  groupList={};
  @Input() model:any;
  delay:any;
  search:string="";
  loaded:boolean=true;
  maxPage:number=0;
  page:number=0;
  pageSize:number=50;
  selectedChildren=[];

  constructor(private data:DataService) { }

  ngOnInit() {
    this.child=this.originalChild=null;
    this.JSON=JSON;
    if(this.level===1)
      this.getChild();
    Object.keys(this.model).forEach(c=>{
      if (JSON.stringify(this.group)===c){
        if(this.model[c])
          this.getChild();
        else
          this.child=this.originalChild=null;
      }
    });
  }

  getGroups(pg){
    if(!pg)
      this.page=0;
    if(this.delay){
      clearTimeout(this.delay);
    }
    this.delay=setTimeout(()=>{
      this.loaded=false;
      if(this.search)
        this.child=this.originalChild.filter(c=>c.name[c.name.length-1].match(new RegExp(this.search,'i')));
      else
        this.child=[...this.originalChild];
      this.maxPage=Math.ceil(this.child.length/this.pageSize);
      this.child=this.child.slice(pg*this.pageSize,(pg+1)*this.pageSize);
      this.loaded=true;
    },100);
  }

  pageBack(pg){
    this.page=pg;
    if(this.child){
      this.page>0?this.page--:0;
      this.getGroups(this.page);
    }
  }

  pageForward(pg){
    this.page=pg;
    if(this.child){
      (this.maxPage>=this.page+1)?this.page++:this.page;
      this.getGroups(this.page);
    }
  }

  getChild(){
    let exp=this.group?JSON.stringify(this.group).slice(0,-1):this.group;
    if (this.level===4){
      this.child=[];
      this.originalChild=[...this.child];
      this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loaded=true;
      this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
      return
    }
    if(this.groupList[exp]){
      this.child=[...this.groupList[exp]];
      this.originalChild=[...this.child];
      this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loaded=true;
      this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
      return
    }
    this.loaded=false;
    if(this.originalChild&&this.originalChild.length){
      this.loaded=true;
      this.originalChild=[...this.child];
      this.groupList[exp]=[...this.child];
      this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loaded=true;
      this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
    }
    else{
      this.data.getGroups(this.group?this.group.name:this.group,this.level).subscribe(r=>{
        this.child=r.results.map(c=>({_id:c._id,name:JSON.parse(c.name+']')}));
        this.originalChild=[...this.child];
        this.groupList[exp]=[...this.child];
        this.group?this.model[JSON.stringify(this.group)]=true:null;
        this.loaded=true;
        this.child=this.originalChild.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
        this.loaded=true;
        this.maxPage=Math.ceil(this.originalChild.length/this.pageSize);
      },e=>{console.error(e);this.loaded=true;});
    }  
  }

  changeEvent(ev:any){
    let updVal=this.model[JSON.stringify(ev)];
    if(!updVal) {
      Object.keys(this.model).filter(c=>(JSON.stringify(JSON.parse(c).name).slice(0,-1)).indexOf(JSON.stringify(ev.name).slice(0,-1))>=0).forEach(c=>{this.model[c]=false});
      this.child=this.originalChild=null;
        this.data.selectedChildren.splice(this.data.selectedChildren.indexOf(ev),1);
    }
    else{
      Object.keys(this.model).filter(c=>ev.name.includes(JSON.stringify(JSON.parse(c).name).slice(0,-1))).forEach(c=>{this.model[c]=true});
        this.data.selectedChildren.push(ev);
    }
  }
}
