import { Component, OnInit, Output, ElementRef, ViewChild, Input, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatChipInputEvent, MatAutocomplete} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-chip-groups',
  templateUrl: './chip-groups.component.html',
  styleUrls: ['./chip-groups.component.scss']
})
export class ChipGroupsComponent implements OnInit {
  @Input() items: any;
  viewItem:any;
  @Input() title: string;
  selectedItems:any[]=[];
  visible = true;
  selectable = true;
  cachedItems:any[]=[];
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fieldCtrl = new FormControl();
  filteredItems: Observable<string[]>;
  @Output() emt=new EventEmitter<any>();
  @Output() rem=new EventEmitter<any>();

  @ViewChild('fieldInput') fieldInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoTrigger') matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(private data:DataService) {
    
   }

  ngOnInit() {
    this.cachedItems=this.items;
    this.filterStart();
  }

  filterStart(){
    this.filteredItems = this.fieldCtrl.valueChanges.pipe(
      startWith(null),
      map((f: string | null) => f&&f.length!==0&&f!=='' ? this._filter(f) : this.items.slice()));
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.selectedItems.push([...this.selectedItems[this.selectedItems.length-1],value.trim()]);
        this.items=[];
        this.filterStart();
        this.emt.emit(this.selectedItems);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.fieldCtrl.setValue(null);
      setTimeout(() => {
        this.matAutocompleteTrigger.openPanel();
      }, 333);
    }
  }

  remove(f: any): void {
    const index = this.selectedItems.indexOf(f);
    if (index >= 0) {
     
      // this.selectedItems.splice(index, 1);
      this.selectedItems=[];
      this.items=this.cachedItems;
      this.filterStart();
      this.emt.emit(this.selectedItems);
    
      setTimeout(() => {
        this.matAutocompleteTrigger.closePanel();
      }, 100);
    }

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.value);
    this.emt.emit(this.selectedItems);
    this.fieldInput.nativeElement.value = '';
    this.fieldCtrl.setValue(null);
    this.data.getGroups([this.selectedItems[this.selectedItems.length-1]],this.selectedItems.length+1).subscribe(r=>{
      this.items=r.results.map(c=>JSON.parse(c.name+']'));
      this.filterStart();
    },e=>{console.log(e)})
    setTimeout(() => {
      this.matAutocompleteTrigger.openPanel();
    }, 333);
  }

  private _filter(value: any): string[] {
    let filterValue;
    if(!Array.isArray(value))
      filterValue = value.toLowerCase();
    else
      filterValue = value.map(c=>JSON.stringify(c[c.length-1]+']').toLowerCase());
    return this.items.filter(f => JSON.stringify(f[f.length-1]+']').toLowerCase().indexOf(filterValue) >= 0);
  }

}
