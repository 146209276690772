import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-bulk-insert-properties',
  templateUrl: './bulk-insert-properties.component.html',
  styleUrls: ['./bulk-insert-properties.component.scss']
})
export class BulkInsertPropertiesComponent implements OnInit {
  fields:any={};
  fieldTitles:any={};
  exportData:any={};
  disableDownload:boolean=true;
  dataArray:any=[];
  dataArrayB:any=[];
  showArray:any=[];
  object:Object;
  length:number=0;
  pageSize:number=10;
  page:number=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loading:boolean=false;
  error:string;
  loadingPaste:boolean=false;
  isInRole: any;

  constructor(private data:DataService,private excelService: ExcelService,private snackBar: MatSnackBar,private auth:AuthService) { 
    this.isInRole = this.auth.isInRole(['Admin']);
  }
  ngOnInit() {
    this.object=Object;
      this.fields["Όνομα Ιδιότητας"]='name';
      this.fieldTitles['name']="Όνομα Ιδιότητας";

      this.disableDownload=false;
  }

  onPaste(event: ClipboardEvent){
    if (this.loading) return
    this.loadingPaste=true;
    this.error="";
    let tempArray=[];
    event.clipboardData.getData('text').split('\n').forEach((c,i)=>{
      if (c&&c.trim()) 
        tempArray.push(c.split('\t').map(d=>d.trim()))}
    );
    this.dataArray=tempArray.slice(1).map(c=>{
      let tmp={};
      tempArray[0].forEach((d,i)=>{
        c[i]=c[i].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
        if (c[i])
          tmp[this.fields[d]]=c[i];
      });
      return tmp;
    })
    this.dataArray=this.dataArray.filter(c=>!c.undefined);
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
    this.length=this.dataArray.length;
    this.loadingPaste=false;
  }

  exportAsXLSX():void {
    if (this.loading) return
    Object.keys(this.fields).forEach(c=>{this.exportData[c]=''});
    this.excelService.exportAsExcelFile([this.exportData], 'template_properties');
 }

  exportDataAsXLSX():void {
    if (this.loading) return
    let exportData=this.data.deepCopy(this.dataArray);
    exportData=exportData.map(c=>{

      Object.keys(this.fieldTitles).forEach(e=>{
        if (!c[e])
          c[e]="";
      });

      Object.keys(c).map(d=>delete Object.assign(c, {[this.fieldTitles[d]]: c[d] })[d]);
      return c;
    })
    this.excelService.exportAsExcelFile(exportData,'data');
    this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 pageChange(pSize,pIndex){
  this.pageSize=pSize;
  this.page=pIndex;
  this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
 }

 requestSequence(){
  let requestTable=[];
  this.dataArrayB=[...this.dataArray];
  while(this.dataArray.length){
    requestTable.push(this.dataArray.slice(0,50));
    this.dataArray=this.dataArray.slice(50);
  }
  return Promise.all(requestTable.map(c=>{
    return new Promise((resolve,reject)=>{
      this.data.createProperties(c.map(d=>d.name)).subscribe(r=>{
        r.forEach(c=>{
          let idx;
          if(c.filter && (idx=this.dataArrayB.map(d=>{return d.name||''}).indexOf(c.filter.name?c.filter.name:''))>=0)
            this.dataArrayB.splice(idx,1);
        });
        resolve(r);
      },e=>{console.error(e); resolve(e)})
    })
  }))
 }

 insertData(){
  if (this.loading) return
  this.error="";
  this.loading=true;
  this.requestSequence().then(r2=>{
    this.dataArray=[...this.dataArrayB];
    if(this.dataArray.length){
      this.requestSequence().then(r3=>{
        this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
        this.loading=false;
        this.length=this.dataArray.length;
        if (this.dataArray.length)
          this.error="Σφάλμα: Κάποιες εγγραφές δεν εισήχθησαν";
        else
          this.snackBar.open("Εισαγωγή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});    
      }).catch(e=>console.error(e));
    }
    else{
      this.showArray=this.dataArray.slice(this.page*this.pageSize,(this.page+1)*this.pageSize);
      this.loading=false;
      this.length=this.dataArray.length;
      if (this.dataArray.length)
        this.error="Σφάλμα: Κάποιες εγγραφές δεν εισήχθησαν";
      else
        this.snackBar.open("Εισαγωγή Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});  
    }
  })
  .catch(e=>console.error(e));
 }

}
