import { MatPaginatorIntl } from '@angular/material';

export function ContactPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Πλήθος επαφών για προβολή:';
    paginatorIntl.firstPageLabel = 'Πρώτη σελίδα';
    paginatorIntl.nextPageLabel = 'Επόμενη Σελίδα';
    paginatorIntl.previousPageLabel = 'Προηγούμενη σελίδα';
    paginatorIntl.lastPageLabel = 'Τελευταία σελίδα';
    
    return paginatorIntl;
}
