import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-property-edit',
  templateUrl: './property-edit.component.html',
  styleUrls: ['./property-edit.component.scss']
})
export class PropertyEditComponent implements OnInit,OnDestroy {

  @Input() selectedProperty: any;
  @Input() deleteMode: boolean;
  contacts:any;
  contactFields:any;
  length = 0;
  pageSize = 10;
  pageIndex=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  delay:any;
  searchText:string="";
  subscriptionUpdateProperties:Subscription;
  selectedGroups:any={};
  selectedProperties:any=[];
  properties:any={};
  propertiesTitles:any[];
  loading:boolean;

  constructor(private data:DataService,private snackBar:MatSnackBar) { 
  }

  ngOnInit() {
    this.data.getContactsByProperties([],[],this.searchText,this.selectedProperty._id,10,1,!this.deleteMode).subscribe(r=>{
      this.contacts=r.results;
      this.contactFields=r.fields;
      this.length=r.count;
    },err=>console.error(err));
    this.data.getProperties("").subscribe(r=>{
      r.results.forEach(c=>{
        this.properties[c.name]=c._id;
      });
      this.propertiesTitles=Object.keys(this.properties);
    },e=>console.log(e));
    this.subscriptionUpdateProperties=this.data.eventUpdateProperties.subscribe(()=>{this.search()})
  }

  ngOnDestroy(){
    this.subscriptionUpdateProperties.unsubscribe();
  }

  search(){
    if(this.delay){
      clearTimeout(this.delay);
    }
      this.delay=setTimeout(()=>{
        this.delay=null;
        this.data.getContactsByProperties(Object.keys(this.selectedGroups).filter(c=>this.selectedGroups[c]).map(c=>JSON.parse(c)._id),this.selectedProperties.map(c=>this.properties[c]),this.searchText,this.selectedProperty._id,this.pageSize,this.pageIndex+1,!this.deleteMode).subscribe(c=>{
          this.contacts=c.results;
          this.contactFields=c.fields;
          this.length=c.count;
        },e=>{console.log(e)});
      },1000);
  }

  deleteConnection(contact){
    console.log(contact,this.selectedProperty.level);
    this.data.deletePropertyConnection(contact._id,this.selectedProperty._id).subscribe(r=>{
      if(r.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.emitEventUpdateProperties();
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  addConnection(contact){
    this.data.addPropertyConnection(contact._id,this.selectedProperty._id).subscribe(r=>{
      if(r.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.emitEventUpdateProperties();
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  onPropertiesChange(data){
    this.selectedProperties=data;
    this.search();
  }

  pageUpdate(pS,pI){
    this.pageSize=pS;
    this.pageIndex=pI;
    this.search();
  }

}
