import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss']
})
export class CategoryCreateComponent implements OnInit {
  groups:any[]=[]

  constructor(private data:DataService) { }

  ngOnInit() {
    this.data.getGroups("",1).subscribe(r=>{
      this.groups=r.results.map(c=>JSON.parse(c.name+']'));
    },e=>console.log(e))
  }

  onSubmit(){
    
  }

}
