import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation,ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DropdownListComponent implements OnInit {

  @Input() loop:any[];
  groups:any;
  originalGroups:any;
  groupsList={};
  titles:string[]=["Ομάδες Επαφών", "Κατηγορίες", "Υποκατηγορίες"];
  search:string="";
  delay:any;
  @Output('dListEmit') dListEmit=new EventEmitter<any>();

  constructor(private data:DataService) { }

  ngOnInit() {
  }

  getGroups(){
    if(this.delay){
      clearTimeout(this.delay);
    }
    this.delay=setTimeout(()=>{
      if(this.search)
        this.groups=this.originalGroups.filter(c=>c.name[c.name.length-1].match(new RegExp(this.search,'i')));
      else
        this.groups=[...this.originalGroups];
    },500);
  }

  groupSelect(l,data){
    l.push(data);
    this.dListEmit.emit(this.loop);
  }

  unselect(l,i){
    l.splice(i+1,l.length-i-1);
    this.dListEmit.emit(this.loop);
  }

  populateGroups(ev,exp,level){
    ev.stopPropagation();
    this.originalGroups=this.groups=[];
    let regexp=exp[level-1]?exp[level-1].name:exp[level-1];
    if(this.groupsList[regexp]){
      this.originalGroups=this.groups=this.groupsList[regexp];
      return
    }
    this.data.getGroups(regexp,level+1).subscribe(c=>{
      this.groupsList[regexp]=this.originalGroups=this.groups=c.results.map(c=>{return{_id:c._id,name:JSON.parse(c.name+']')}});
    },e=>{console.log(e)});
  }

  addLoop(){
    this.loop.push([]);
  }

  removeLoop(i){
    this.loop.splice(i,1);
    this.dListEmit.emit(this.loop);
  }

}
