import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import {MatSnackBar, MatDialog} from '@angular/material';
import { ConfirmDeleteComponent } from '../../helpers/confirm-delete/confirm-delete.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss']
})
export class PropertiesListComponent implements OnInit {

  properties:any=[];
  originalProperties:any=[];
  selectedProperties:any=[];
  selectedProperty:any;
  updatedProperty:any;
  showEdit:boolean=false;
  showDeleteConnections:boolean=false;
  showInsertConnections:boolean=false;
  pageSize:number=10;
  length:number=0;
  pageIndex:number=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  loaded=false;
  isInRole: boolean;
  delay:any;
  search:string="";
  isInRoleMod: boolean;

  constructor(private data:DataService,private snackBar: MatSnackBar, private auth:AuthService, public dialog:MatDialog) { 
    this.isInRole = this.auth.isInRole(['Admin']);
    this.isInRoleMod = this.auth.isInRole(['Moderator']);
  }

  ngOnInit() {
    this.getProperties();
  }

  getProperties(){
    this.loaded=false;
    this.data.getProperties("").subscribe(r=>{
      this.originalProperties=r.results;
      this.properties=this.originalProperties.slice(this.pageIndex*this.pageSize,(this.pageIndex+1)*this.pageSize);
      this.length=this.originalProperties.length;
      this.loaded=true;
    },e=>{console.log(e);this.loaded=true;})
  }

  createProperties(){
    this.selectedProperties.forEach((c,i) => {
      this.selectedProperties[i]=this.data.sanitizeString(c);
    });
    if(this.selectedProperties.length){
      this.data.createProperties(this.selectedProperties).subscribe(d=>{
        if(d.length>0)
          this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.getProperties();
     },e=>{
       this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
       console.error(e);
      });
    }
  }

  searchProperties(pg){
    if(!pg)
      this.pageIndex=0;
    if(this.delay){
      clearTimeout(this.delay);
    }
    this.delay=setTimeout(()=>{
      this.loaded=false;
      if(this.search)
        this.properties=this.originalProperties.filter(c=>c.name.match(new RegExp(this.search,'i')));
      else
        this.properties=[...this.originalProperties];
      this.length=this.properties.length;
      this.properties=this.properties.slice(pg*this.pageSize,(pg+1)*this.pageSize);
      this.loaded=true;
    },100);
  }

  
 pageChange(pSize,pIndex){
  this.pageSize=pSize;
  this.pageIndex=pIndex;
  this.properties=this.originalProperties.slice(this.pageIndex*this.pageSize,(this.pageIndex+1)*this.pageSize);
 }

  onFieldsChange(s){
    this.selectedProperties=s.map(c=>this.data.sanitizeString(c));
  }

  editProperty(p){
    this.selectedProperty=p;
    this.updatedProperty=this.selectedProperty.name;
    this.showEdit=!this.showEdit;
    if(this.showEdit)
      window.scrollTo(0,document.body.scrollHeight);
  }

  editPropertyConnections(p){
    this.selectedProperty=p;
    this.showDeleteConnections=!this.showDeleteConnections;
    this.showInsertConnections=false;
    if(this.showDeleteConnections)
      window.scrollTo(0,document.body.scrollHeight);
  }

  toggleInsert(){
    this.showInsertConnections=!this.showInsertConnections;
  }

  updateProperty(){
    this.data.updateProperty(this.selectedProperty._id,this.updatedProperty).subscribe(d=>{
      if(d.nModified){
        this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
        this.getProperties();
        this.selectedProperty.name=this.updatedProperty;
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.error(e);
    });
  }

  confirmDeleteDialog(){
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      data: {confirmString:this.selectedProperty.name}
    });

    dialogRef.afterClosed().subscribe(result => { 
      if (result)
        this.deleteProperty();
    });
  }

  deleteProperty(){
    this.data.deleteProperty(this.selectedProperty._id).subscribe(d=>{
      if(d.result._id===this.selectedProperty._id){
        this.snackBar.open("Επιτυχής Δημιουργία",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']})
        this.getProperties();
        this.showEdit=false;
      }
   },e=>{
     this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
     console.error(e);
    });
  }

}
