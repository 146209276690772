import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { ConfirmInactiveComponent } from '../../helpers/confirm-inactive/confirm-inactive.component';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit,OnDestroy {

  @Input() selectedGroup: any;
  @Input() deleteMode: boolean;
  contacts:any;
  contactFields:any;
  length = 0;
  pageSize = 10;
  pageIndex=0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  delay:any;
  searchText:string="";
  subscriptionUpdateGroups:Subscription;
  selectedGroups:any={};
  selectedProperties:any=[];
  properties:any={};
  propertiesTitles:any[];
  loading:boolean;
  
  constructor(private data:DataService,private snackBar:MatSnackBar, private dialog: MatDialog) { 
  }

  ngOnInit() {
    this.data.getContactsByGroup([],[],this.searchText,this.selectedGroup._id,10,1,!this.deleteMode).subscribe(r=>{
      this.contacts=r.results;
      this.contactFields=r.fields;
      this.length=r.count;
    },err=>console.error(err))
    this.subscriptionUpdateGroups=this.data.eventUpdateGroups.subscribe(()=>{this.search()});
    this.data.getProperties("").subscribe(r=>{
      r.results.forEach(c=>{
        this.properties[c.name]=c._id;
      });
      this.propertiesTitles=Object.keys(this.properties);
    },e=>console.log(e))
  }

  ngOnDestroy(){
    this.subscriptionUpdateGroups.unsubscribe();
  }

  inActiveAll(){
   this.data.inactiveGroup(this.selectedGroup._id).subscribe(s=>{
    this.data.getContactsByGroup(Object.keys(this.selectedGroups).filter(c=>this.selectedGroups[c]).map(c=>JSON.parse(c)._id),this.selectedProperties.map(c=>this.properties[c]),this.searchText,this.selectedGroup._id,this.pageSize,this.pageIndex+1,!this.deleteMode).subscribe(c=>{
      this.contacts=c.results;
      this.contactFields=c.fields;
      this.length=c.count;
    },e=>{console.log(e)});
   });
  }
  
  confirmInactiveDialog(){
    const dialogRef = this.dialog.open(ConfirmInactiveComponent);

    dialogRef.afterClosed().subscribe(result => { 
      if (result)
        this.inActiveAll();
    });
  }

  search(){
    if(this.delay){
      clearTimeout(this.delay);
    }
      this.delay=setTimeout(()=>{
        this.delay=null;
        this.data.getContactsByGroup(Object.keys(this.selectedGroups).filter(c=>this.selectedGroups[c]).map(c=>JSON.parse(c)._id),this.selectedProperties.map(c=>this.properties[c]),this.searchText,this.selectedGroup._id,this.pageSize,this.pageIndex+1,!this.deleteMode).subscribe(c=>{
          this.contacts=c.results;
          this.contactFields=c.fields;
          this.length=c.count;
        },e=>{console.log(e)});
      },1000);
  }

  deleteConnection(contact){
    console.log(contact,this.selectedGroup.level);
    this.data.deleteGroupConnection(contact._id,this.selectedGroup.name,this.selectedGroup.name.length).subscribe(r=>{
      if(r.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.emitEventUpdateGroups();
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }


  addConnection(contact){
    this.data.addGroupConnection(contact._id,this.selectedGroup.name,this.selectedGroup.name.length).subscribe(r=>{
      if(r.nModified>0){
        this.snackBar.open("Επεξεργασία Επιτυχής",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-success']});
        this.data.emitEventUpdateGroups();
      }
    },e=>{
      this.snackBar.open("Ανεπιτυχής Ενέργεια",null,{duration:1000,horizontalPosition:'right',verticalPosition:'top',panelClass:['snack-error']});
      console.error(e);
    });
  }

  onPropertiesChange(data){
    this.selectedProperties=data;
    this.search();
  }

  pageUpdate(pS,pI){
    this.pageSize=pS;
    this.pageIndex=pI;
    this.search();
  }

}
